<template>
  <div id="reset-password-page">
    <AppHeader />
    <div class="reset-password-content">
      <div class="content-container">
        <b-row class="reset-password-form" no-gutters>
          <b-col
            :class="{'hide' : breakpoint !== 'xl'}"
            cols="12"
            :lg="breakpoint !== 'xl' ? 12 : 6"
          ></b-col>
          <b-col
            class="my-auto"
            cols="12"
            :lg="breakpoint !== 'xl' ? 12 : 6">
            <div class="form-container">
              <h4 class="text-center mb-3">Reset Password</h4>
              <b-form @submit.prevent="verifyResetPass">
                <b-input-group prepend="Password">
                  <b-form-input
                    type="password"
                    aria-describedby="Password Input"
                    autocomplete="off"
                    placeholder="New Password"
                    v-model.trim="$v.form.password.$model"
                    :state="!$v.form.password.$error"
                    @input="$v.form.password.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.password.required">
                      Password is required.
                    </span>
                    <span v-if="!$v.form.password.minLength &&
                            $v.form.password.required">
                      Password must have at least
                      {{$v.form.password.$params.minLength.min}} characters.
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>

                <b-input-group prepend="Confirm Password">
                  <b-form-input
                    type="password"
                    aria-describedby="Confirm Password Input"
                    autocomplete="off"
                    placeholder="Confirm Password"
                    v-model.trim="$v.form.cpassword.$model"
                    :state="!$v.form.cpassword.$error"
                    @input="$v.form.cpassword.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.cpassword.required">
                      Password is required.
                    </span>
                    <span v-if="!$v.form.cpassword.minLength &&
                            $v.form.cpassword.required">
                      Confirm Password must have at least
                      {{$v.form.cpassword.$params.minLength.min}} characters.
                    </span>
                    <span v-if="!$v.form.cpassword.sameAsPassword &&
                            $v.form.cpassword.required &&
                            $v.form.cpassword.minLength">
                      Passwords must be the same.
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>

                <div class="text-center">
                  <b-button
                    class="btn-h3-orange"
                    type="submit"
                    :disabled="$v.form.$invalid">
                    Submit
                  </b-button>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <AppFooter class="footer" />
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import _axios from 'axios';
  import md5 from 'md5';
  import { required, sameAs, minLength } from 'vuelidate/lib/validators';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    head : {
      title : {
        inner      : 'Reset Password',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        instanceResetPassword : null,
        loading               : false,
        form                  : {
          password  : null,
          cpassword : null,
        },
      }
    },
    components : {
      'AppHeader' : () => import('@/components/layout/AppHeader'),
      'AppFooter' : () => import('@/components/layout/AppFooter'),
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    methods : {

      /**
       * Verify if User exists
       */
      verifyResetPass() {
        this.loading = true;
        this.instanceResetPassword.post('auth/verifyResetPassword', {
          userId    : this.$route.params.userId,
          emailHash : md5(this.$route.params.email),
        }).then(() => {
          this.resetPassword();
        }).catch(() => {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'User does not exist!',
          });
        });
      },

      /**
       * Reset Password
       */
      resetPassword() {
        this.instanceResetPassword.post('auth/resetPassword', {
          userId   : this.$route.params.userId,
          password : this.form.password,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Reset Password successfully!',
          });

          setTimeout(() => { this.$router.push('/login'); }, 2000);
        }).catch(err => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : err.response.status === 401 ?
              'Reset password link no longer available' :
              'Unable to reset password',
          });
        }).finally(() => {
          this.loading = false;
        });
      },
    },
    mounted() {
      this.instanceResetPassword = _axios.create({
        baseURL : process.env.VUE_APP_ROOT_API,
        headers : {
          'Content-Type'  : 'application/json',
          'Authorization' : 'Bearer ' + this.$route.params.token,
        },
      });

      this.$analytics.fbq.event('PageView', {
        'page'     : 'reset-password',
        'platform' : 'website',
      });
    },
    validations : {
      form : {
        password : {
          required,
          minLength : minLength(5),
        },
        cpassword : {
          required,
          minLength      : minLength(5),
          sameAsPassword : sameAs('password'),
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #reset-password-page {
    background-image: $login-bg;
    background-attachment: fixed;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-container {
    padding: 2rem;
    background-color: #342aff;
    border: 10px solid $login-orange;
    border-radius: 1rem;
    box-shadow: 5px 5px 30px rgba(0,0,0, 0.8);
  }

  .form-control {
    height: auto;
  }

  .input-group-text {
    width: 13em;
    background-color: $login-orange !important;
    color: #fff;
    font-family: "WickedMouse";
    font-size: 1em;
    -webkit-text-stroke: 1px $login-purple;
  }

  .invalid-feedback {
    margin-left: 13em;
    color: #fff !important;
  }

  a {
    text-decoration: none !important;
  }

  .hide {
    display: none;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .reset-password-content {
      min-height: calc(100vh - 3.5em);
      .reset-password-form {
        height: 94vh;
      }
    }
    .input-group-text {
      display: none;
    }
    .invalid-feedback {
      margin-left: 0;
    }
  }

  /* iPhone 6/7/8 Plus */
  @media only screen
    and (device-width : 414px)
    and (device-height : 736px)
    and (-webkit-device-pixel-ratio : 3) {
    .reset-password-content {
      min-height: calc(100vh - 2.5em);
      .reset-password-form {
        height: calc(100vh - 9.4em);
      }
    }
  }

  /* iPhone X and Xs Max */
  @media only screen
    and (min-device-width: 375px)
    and (min-device-height: 812px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    .reset-password-content {
      min-height: calc(100vh - 2.5em);
      .reset-password-form {
        height: calc(100vh - 9.3em);
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .reset-password-content {
      .reset-password-form {
        height: calc(100vh - 3.3em);
      }
    }
    .invalid-feedback {
      margin-left: 10rem;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .reset-password-content {
      .reset-password-form {
        height: calc(100vh - 4.9em);
      }
    }
    .form-container {
      margin-bottom: 0;
    }
  }
  
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .reset-password-content {
      .reset-password-form {
        height: calc(100vh - 5em);
      }
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .reset-password-content {
      background-image: $pj-bg;
      background-attachment: fixed;
      background-position: left bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  
  @media only screen and (min-width: $special) {
    .reset-password-content {
      .reset-password-form {
        padding-top: 3rem;
        height: calc(100vh - 4.2em);
      }
    }
  }
  
  @media only screen and (min-width: $xl2-min) {
    .reset-password-content {
      min-height: calc(100vh - 3.8em);
      .reset-password-form {
        height: calc(100vh - 10em);
      }
    }
    .invalid-feedback {
      margin-left: 18rem;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .reset-password-content {
      .reset-password-form {
        height: calc(100vh - 4.6em);
      }
    }
  }
</style>